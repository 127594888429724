<template>
  <div class="button" :class="secondaryStyles">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isSecondary: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    secondaryStyles() {
      let classes = '';
      if (this.isSecondary) classes += 'secondary ';
      if (this.isDisabled) classes += 'disabled ';
      return classes;
    }
  }
};
</script>

<style scoped>
.button {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #000;
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22), 0 14px 28px 0 rgba(0, 0, 0, 0.25);
}

.button:active {
  box-shadow: none;
}

.secondary {
  border: 2px solid #000;
  background-color: transparent;
  transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1), background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #000;
}

.secondary:hover {
  background-color: #000;
  color: #fff;
}

.disabled {
  color: #999;
  background-color: lightgray;
  cursor: not-allowed;
}

.disabled:hover {
  box-shadow: none;
}
</style>
