'use strict';

// Specified actions for mutating the notes store; this controls Stage rendering.
export default {

  // Set the debug mode for the Stage.
  setDebug({ commit }, { debug }) {
    commit('setDebug', { debug });
  }
};
