<template>
  <div class="notFound">
    <div class="title">
      404, Page not found!
    </div>
    <Button @click="routeTo('/')">
      Return Home
    </Button>
  </div>
</template>

<script>
'use strict';

// Imports.
import { useRouter } from 'vue-router';

// Component imports.
import Button from '/src/components/ui/Button.vue';

// Export the default component.
export default {
  components: {
    Button
  },

  setup() {
    const router = useRouter();

    const routeTo = url => {
      router.push('/');
    };

    return {
      routeTo
    };
  }
};
</script>

<style scoped lang="scss">
.notFound {
	height: 90vh;
	margin-bottom: 10vh;
	margin-top: 10vh;
  padding: 60px;
  text-align: center;
  .title {
    font-size: 60px;
    font-weight: 800;
    margin-bottom: 40px;
		color: white;
  }
}
</style>
