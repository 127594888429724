'use strict';

// Export state mutation functions.
export default {

  // Updates the debug status.
  setDebug (state, { debug }) {
    state.debug = debug;
  }
};
