<template>

  <!-- Support a pop-out mobile navigation menu for the site. -->
  <transition name="fade">
    <div v-show="isVisible" class="mobile-background" @click.stop="triggerClose">
    </div>
  </transition>

  <!-- Define the content present in the mobile menu. -->
  <transition name="slideright">
    <div v-show="isVisible" class="mobileMenu" @click="triggerClose">
      <div class="closeIcon">
        <CloseIcon />
      </div>

      <!-- Display the user's address if one is available. -->
      <div class="link address" v-if="ethereum.address">
        {{ profileAddressLabel }}
      </div>

      <!-- The user is not connected to a signing provider. -->
      <div class="link" v-if="!ethereum.address" @click.stop="showWalletModalDisplay">
        {{ walletPrompt }}
      </div>

      <!-- Display the main content of the mobile menu. -->
      <div class="link" @click.stop="routeTo('/')">
        Home
      </div>
      <a class="link" @click.stop="routeTo('/mint')">
        Mint
      </a>
      <a class="link" @click.stop="routeTo('/explore')">
        Explore
      </a>
      <a class="link" @click.stop="routeTo('/about')">
        About
      </a>
      <a class="link" @click.stop="routeTo('/faq')">
        FAQ
      </a>

      <!-- Link to the project Discord. -->
      <div class="link" @click="openUrl('https://discord.gg/kCTvkXdTWe')">
        <DiscordIcon style="margin-right:12px;"/> Discord
      </div>

      <!-- Link to the project Twitter. -->
      <div class="link" @click="openUrl('https://twitter.com/ArtChoir')">
        <TwitterIcon style="margin-right:12px;"/> Twitter
      </div>
    </div>
  </transition>
</template>

<script>
'use strict';

// Imports.
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatAddress } from '/src/utility/format';

// Icon imports.
import CloseIcon from '/src/components/icons/CloseIcon.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';

// Export the default component.
export default {
  components: { CloseIcon, DiscordIcon, TwitterIcon },

  emits: ['toggleVisibility', 'showWalletModal'],

  props: {
    isVisible: Boolean,
    isHome: Boolean
  },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const triggerClose = () => {
      window.onscroll = function() {};
      context.emit('toggleVisibility');
    };

    const openUrl = url => {
      window.open(url, '_blank');
    };

    const routeTo = url => {
      router.push(url);
    };

    const ethereum = computed(() => {
      return store.state.ethers;
    });

    const profileAddressLabel = computed(() => {
      return formatAddress(ethereum.value.address);
    });

    const walletPrompt = computed(() => {
      return 'Connect Wallet';
    });

    const showWalletModalDisplay = () => {
      context.emit('showWalletModal');
    };

    return {
      triggerClose,
      openUrl,
      routeTo,
      ethereum,
      profileAddressLabel,
      walletPrompt,
      showWalletModalDisplay
    };
  }
};
</script>

<style scoped lang="scss">
.mobileMenu {
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1000;
  padding: 20px 60px 20px 20px;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 30px;
  background-color: grey;
  color: white;
  display: flex;

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 40px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }

  .address {
    border: 2px solid hsla(0, 0%, 100%, 0.5);
    background-color: hsla(0, 0%, 100%, 0.1);
    cursor: default;
    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }

    span {
      padding: 3px;
      border-radius: 50%;
      background-color: white;
      margin: auto;
      margin-right: 10px;
    }
  }

  .closeIcon {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

.mobile-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1000vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

// Animations
.slideright-leave-active,
.slideright-enter-active {
  transition: all 0.5s ease;
}

.slideright-enter-from,
.slideright-leave-to {
  transform: translateX(100vw);
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
