<template>
	<Ethereum :callback="initialize" />

		<img src="https://www.w3schools.com/images/lamp.jpg" class="note-image" v-if="!isPlaying" @click.stop="playItem"/>
		<iframe src="/stage" title="StageIFrame" class="note-iframe" ref="iframe" loading="lazy" v-else></iframe>

</template>

<script>
'use strict';

// Imports.
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { formatNumber } from '@/utility';

// Icon imports.

// Component imports.
import Ethereum from '@/components/common/Ethereum.vue';

// Export the default component.
export default {
  components: {
		Ethereum
  },

	setup (props, context) {
    const store = useStore();
		const isPlaying = ref(false);

		// Mount the component.
		onMounted(() => {

		});

		const initialize = function () {
			walletAddress.value = store.state.ethers.address;
			console.log(walletAddress.value)
		};

		const playItem = function () {
			isPlaying.value = true;
		}

		return {
			initialize,
			isPlaying,
			playItem
		}
	}
};

</script>

<style scoped>
	.note-image {
		width: 100%;
		height: 100%;
		border: solid 1px rgba(255, 255, 255, 0.1);
		border-radius: 20px;
	}

	.note-iframe {
		outline: none;
		height: 100%;
		width: 100%;
		border: solid 1px rgba(255, 255, 255, 0.1);
		border-radius: 20px;
	}
</style>
