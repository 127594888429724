<template>
	<Ethereum :callback="initialize" />

  <div class="mint-main">
		<vue-final-modal name="mint-modal" :lock-scroll="true" :click-to-close="true" :esc-to-close="true" :zIndex="2" v-model="modalIsOpen">
      <div class="modal-container" @click.self="closeModal">
				<div class="mint-modal-container">
					<div class="modal-title">
						Mint Loot
					</div>
					<div class="buy-notes">
						<div class="cart-info-text">
								Mint amount:
						</div>
						<div class="notes-in-cart-text">
							<input type="range" min="1" max="16" step="1" list="steplist" id="notesInput" value="1" @input="updateNotesSelected">
								<datalist id="steplist">
									<option>1</option>
									<option>4</option>
									<option>8</option>
									<option>12</option>
									<option>16</option>
								</datalist>
								<div class="note-word-text">
									{{ mintAmount }}
								</div>
						</div>
					</div>
					<div class="button-row">
						<div class="cancel-button" @click.stop="closeModal">
							Cancel
						</div>
						<div class="buy-button">
							Mint
						</div>
					</div>
				</div>
			</div>
    </vue-final-modal>
		<div class="sliding-background">
		</div>
		<div class="mint-content">
			<div class="timer-container" v-if="!live" @click.stop="countdown">
				<div id="countdown">
					<ul>
						<li>
							<span id="days"></span>
							<div class="timer-text">
								Days
							</div>
						</li>
						<li>
							<span id="hours"></span>
							<div class="timer-text">
								Hours
							</div>
						</li>
						<li>
							<span id="minutes"></span>
							<div class="timer-text">
								Minutes
							</div>
						</li>
						<li>
							<span id="seconds"></span>
							<div class="timer-text">
								Seconds
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="mint-button-container" v-if="live && canSign">
				<div class="mint-button" @click.stop="openModal">
					<div class="mint-button-title">
						Mint
					</div>
				</div>
			</div>
			<div class="mint-button-container" v-if="live && !canSign">
				<div class="mint-button" @click.stop="connectWallet">
					<div class="mint-button-supply">
						Please connect your wallet.
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { formatNumber } from '@/utility';

// Component Imports.
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import Ethereum from '@/components/common/Ethereum.vue';

// Set up the default component.
export default {
	components: {
		VueFinalModal,
		Ethereum
  },

	setup (props, context) {
    const store = useStore();
		const live = ref(false);
		const modalIsOpen = ref(false);
		const canSign = ref(false);
		const mintAmount = ref(1);
		const mintText = ref('Loot')

		// Mount the component.
		onMounted(() => {
			countdown();
		});

		const countdown = function () {
			const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

  		let launchDay = Date.UTC(2021, 8, 6, 16, 0, 0);
      let countDown = new Date(launchDay).getTime();

			let daysElement = document.getElementById('days');
			let hoursElement = document.getElementById('hours');
			let minutesElement = document.getElementById('minutes');
			let secondsElement = document.getElementById('seconds');

      let x = setInterval(function() {
        let now = new Date().getTime();
      	let distance = countDown - now;

				daysElement.innerText = Math.floor(distance / (day));
				hoursElement.innerText = Math.floor((distance % (day)) / (hour));
				minutesElement.innerText = Math.floor((distance % (hour)) / (minute));
				secondsElement.innerText = Math.floor((distance % (minute)) / second);

        // Set the page live when the date is reached.
        if (distance < 0) {
          live.value = true;
          clearInterval(x);
        }
      }, 0)
		};

		const connectWallet = function () {
			store.dispatch(
				'alert/showWalletModal',
				{ root: true }
			);
		};

		const initialize = function () {
			canSign.value = store.state.ethers.canSign;
		};

		const openModal = function () {
			modalIsOpen.value = true;
			return modalIsOpen;
		};

		const closeModal = function () {
			modalIsOpen.value = false;
			return modalIsOpen;
		};

		const updateNotesSelected = function () {
			let notesSelectedInput = document.getElementById('notesInput').value;
			mintAmount.value = notesSelectedInput;
		}

		return {
			live,
			openModal,
			closeModal,
			modalIsOpen,
			formatNumber,
			initialize,
			canSign,
			connectWallet,
			updateNotesSelected,
			mintAmount,
			mintText
		}
	}
};
</script>

<style scoped>
	.mint-main {
		display: flex;
		position: relative;
		flex-direction: column;
		height: 85vh;
		margin-top: 10vh;
		margin-bottom: 5vh;
		font-family: -apple-system,
	    BlinkMacSystemFont,
	    "Segoe UI",
	    Roboto,
	    Oxygen-Sans,
	    Ubuntu,
	    Cantarell,
	    "Helvetica Neue",
	    sans-serif;
			overflow: hidden;
	}

	/* Sliding background */
	.sliding-background {
		position: absolute;
		z-index: 0;
		width: 4000px;
	  height: 100%;
	  background-position: 50% 50%;
	  background-size: 100%;
	  background-attachment: scroll;
	  overflow: hidden;
		animation: slide 40s linear infinite alternate;
		filter: brightness(0.5) sepia(50%);
		-webkit-mask:	linear-gradient(transparent, #fff, transparent);
    mask:	linear-gradient(transparent, #fff, transparent);
		mask-mode: alpha;
	}

	@keyframes slide {
	  0% {
	    transform: translate3d(0, 0, 0);
	  }
	  100% {
	    transform: translate3d(-500px, 0px, 0); /* The image width */
	  }
	}

.mint-content {
	position: relative;
	flex: 5;
	overflow: hidden;
}

/* Timer Styling */
.timer-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #e7e8e3;
}

	.timer-container li {
	  display: inline-block;
	  font-size: 1.5em;
	  list-style-type: none;
	  padding: 1em;
	}

	.timer-container span {
	  display: block;
	  font-size: 5rem;
	}

	.timer-text {
		font-size: 40px;
	}

/* Button Styling */
.mint-button-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	transform-style: preserve-3d;
	will-change: transform;
}

.photo-attribution {
	color: gray;
	position: absolute;
	top: 95%;
}

.mint-button {
	width: 400px;
	height: 100px;
	background: white;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
	animation: swapColorBase linear 0.3s;
	animation-fill-mode: forwards;
}

.mint-button:hover {
	box-shadow: 0px 3px 10px 0px #939393;
	cursor: pointer;
	animation: swapColorHover linear 0.3s;
	animation-fill-mode: forwards;
}

@keyframes swapColorBase {
	0% {
		color: white;
		background-color: black;
	}

	100% {
		color: black;
		background-color: white;
	}
}

@keyframes swapColorHover {
	0% {
		color: black;
		background-color: white;
	}

	100% {
		color: white;
		background-color: black;
	}
}

.mint-button-title {
	font-size: 30px;
	user-select: none;
}

.mint-button-supply {
	font-size: 30px;
	user-select: none;
}

/* Modal styling */
.modal-container {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
}

.mint-modal-container {
	position: relative;
	width: 700px;
	height: 500px;
	border-radius: 10px;
	border-style: solid;
	border-color: white;
	border-width: 2px;
	margin: auto;
	display: flex;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 1);
	color: white;
	padding: 30px;
}

.modal-title {
	width: 100%;
	height: 20%;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 50px;
}

.flourish-icon {
	transform: rotateZ(180deg) scaleX(1.8);
	filter: drop-shadow( 0px 0px 1px rgba(255, 255, 255, 1));
}

/* Notes Input Slider Styling */
#notesInput {
  height: 39px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 80%;
	background-color: inherit;
	margin-top: 0px;
}

#notesInput:focus {
  outline: none;
}

#notesInput::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;

  background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #000000;
}

#notesInput::-webkit-slider-thumb {

  border: 2px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}

#notesInput:focus::-webkit-slider-runnable-track {
  background: #FFFFFF;
}

#notesInput::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #000000;
}

#notesInput::-moz-range-thumb {

  border: 2px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #FFFFFF;
  cursor: pointer;
}

#notesInput::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

#notesInput::-ms-fill-lower {
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 8px;

}

#notesInput::-ms-fill-upper {
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 8px;

}

#notesInput::-ms-thumb {
  margin-top: 1px;

  border: 2px solid #000000;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #FFFFFF;
  cursor: pointer;
}

#notesInput:focus::-ms-fill-lower {
  background: #FFFFFF;
}

#notesInput:focus::-ms-fill-upper {
  background: #FFFFFF;
}

.buy-notes {
	width: 100%;
	height: 10%;
	display: flex;
	font-size: 30px;
	padding-top: 10px;
}

	.cart-info-text {
		font-family: 'Lato', sans-serif;
	}

	.notes-in-cart-text {
		flex: 1;
		display: flex;
		padding-left: 10px;
	}

	.note-number {

	}

	.note-word-text {
		display: flex;
		padding-left: 5px;
		font-family: 'Lato', sans-serif;
	}

	#notes-text {
		font-family: 'Lato', sans-serif;
	}

.price-container {
	width: 100%;
	height: 10%;
	display: flex;
}

	.price-text {
		font-size: 30px;
		font-family: 'Lato', sans-serif;
		margin-right: 10px;
	}

	.eth-container {
		display: flex;
		font-family: 'Lato', sans-serif;
	}

	.eth-icon {
		padding-right: 5px;
		transform: scale(1.2);
	}

	.price {
		display: flex;
		font-size: 30px;
		font-family: 'Lato', sans-serif;

	}

.receipt-container {
	width: 100%;
	height: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

	.receipt-info-text {
		font-size: 30px;
		font-family: 'Lato', sans-serif;
	}

	.note-receipt-container {

	}

	.note-receipt-text {
		font-size: 30px;
		font-family: 'Lato', sans-serif;
	}

	.token-receipt-container {

	}

	.token-receipt-text {
		font-size: 30px;
		font-family: 'Lato', sans-serif;
	}

.button-row {
	width: 100%;
	height: 20%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	bottom: 10%;
	left: 50%;
	right: 50%;
}

	.cancel-button {
		width: 200px;
		height: 50px;
		background: white;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		animation: swapColorBase linear 0.3s;
		animation-fill-mode: forwards;
		margin-right: 15px;
		font-size: 25px;
	}

	.cancel-button:hover {
		box-shadow: 0px 3px 10px 0px #939393;
		cursor: pointer;
		animation: swapColorHover linear 0.3s;
		animation-fill-mode: forwards;
	}

	.buy-button {
		width: 200px;
		height: 50px;
		background: white;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		animation: swapColorBase linear 0.3s;
		animation-fill-mode: forwards;
		margin-left: 15px;
		font-size: 25px;
	}

	.buy-button:hover {
		box-shadow: 0px 3px 10px 0px #939393;
		cursor: pointer;
		animation: swapColorHover linear 0.3s;
		animation-fill-mode: forwards;
	}


</style>
