<template>
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7564 0.751988C10.6792 0.674642 10.5875 0.613278 10.4865 0.57141C10.3856 0.529541 10.2774 0.50799 10.1681 0.50799C10.0589 0.50799 9.95067 0.529541 9.84974 0.57141C9.74881 0.613278 9.65712 0.674642 9.57993 0.751988L5.5 4.82358L1.42007 0.743645C1.34282 0.6664 1.25112 0.605126 1.15019 0.563321C1.04927 0.521516 0.941097 0.5 0.831856 0.5C0.722615 0.5 0.614444 0.521516 0.513518 0.563321C0.412593 0.605126 0.32089 0.6664 0.243645 0.743645C0.1664 0.82089 0.105126 0.912593 0.0633211 1.01352C0.0215164 1.11444 -8.13908e-10 1.22262 0 1.33186C8.13909e-10 1.4411 0.0215164 1.54927 0.0633211 1.65019C0.105126 1.75112 0.1664 1.84282 0.243645 1.92007L4.32358 6L0.243645 10.0799C0.1664 10.1572 0.105126 10.2489 0.0633211 10.3498C0.0215164 10.4507 0 10.5589 0 10.6681C0 10.7774 0.0215164 10.8856 0.0633211 10.9865C0.105126 11.0874 0.1664 11.1791 0.243645 11.2564C0.32089 11.3336 0.412593 11.3949 0.513518 11.4367C0.614444 11.4785 0.722615 11.5 0.831856 11.5C0.941097 11.5 1.04927 11.4785 1.15019 11.4367C1.25112 11.3949 1.34282 11.3336 1.42007 11.2564L5.5 7.17642L9.57993 11.2564C9.65718 11.3336 9.74888 11.3949 9.84981 11.4367C9.95073 11.4785 10.0589 11.5 10.1681 11.5C10.2774 11.5 10.3856 11.4785 10.4865 11.4367C10.5874 11.3949 10.6791 11.3336 10.7564 11.2564C10.8336 11.1791 10.8949 11.0874 10.9367 10.9865C10.9785 10.8856 11 10.7774 11 10.6681C11 10.5589 10.9785 10.4507 10.9367 10.3498C10.8949 10.2489 10.8336 10.1572 10.7564 10.0799L6.67642 6L10.7564 1.92007C11.0734 1.60302 11.0734 1.06904 10.7564 0.751988Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
