<template>
	<div class="layout-manager">
		<div class="header-container">
		  <Header />
		</div>
		<div class="router-view-container">
		  <router-view />
		</div>
		<div class="footer-container">
		  <Footer />
		</div>
	</div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';

// Import components.
import Header from '@/pages/layout/Header.vue';
import Footer from '@/pages/layout/Footer.vue';

// Set up the default application component.
export default {
  name: 'MainLayout',

  components: {
    Header,
    Footer
  },

  // Retrieve a new copy of the alert module's state.
  computed: {
    ...mapState({
      alert: (state) => Object.assign({}, state.alert),
      ethereum: (state) => state.ethers,
      visibility: (state) => Object.assign({}, state.visibility)
    })
  },

  // Register the listener for focus detection changes.
  created() {
    this.setup({ threshold: 10 * 60 * 1000 });
  },

  // Support application-wide suspension of Ethers service polling when the
  // application loses focus. This saves on default provider requests.
  methods: {
    ...mapActions('alert', ['clear', 'warning']),
    ...mapActions('visibility', ['setup', 'cleanup']),
    ...mapActions('ethers', ['pause', 'unpause'])
  },

  // Remove the change detection event listeners upon application cleanup.
  beforeUnmount() {
    this.cleanup();
  },

  // Watch the alert module's state for any toast notifications to show.
  watch: {
    alert: {
      deep: true,
      handler: function(newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let messageType = newAlert.type;
        let duration = newAlert.duration;
        if (message && messageType) {
          this.$toast.show(message, {
            type: messageType,
            position: 'bottom-left',
            duration: duration,
            max: 3
          });
          this.lastMessage = message;
        } else {
          this.$toast.clear();
        }
      }
    },

    // Watch the visibility module's state for reacting to page visibility
    // updates.
    visibility: {
      deep: true,
      handler: async function(newVisibility) {
        let hidden = newVisibility.hidden;
        let probablyHidden = newVisibility.probablyHidden;
        if (hidden || probablyHidden) {
          await this.pause();
        }
        if (probablyHidden) {
          await this.warning({
            message: `It seems like you've been inactive for a while. Are you still there?`,
            duration: false
          });
        }
        if (!hidden && !probablyHidden) {
          await this.unpause();
          await this.clear();
        }
      }
    }
  }
};
</script>

<style scoped>
.layout-manager {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
	overflow-y: auto;
	position: relative;
	width: 100%;
	user-select: none;
}

	.header-container {
		position: absolute;
		width: 100%;
		z-index: 10;
		max-height: 10vh;
	}

	.router-view-container {
		flex: 1;
		height: 100%;
	}

	.footer-container {
		position: absolute;
		bottom: 0%;
		text-shadow: 0 0 5px #ffffff;
		height: 100px;
		max-height: 5vh;
		width: 100%;
		z-index: 10;
	}
	</style>
