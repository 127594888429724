<template>
	<Ethereum :callback="initialize" />
  <div class="profile-container">
		<div class="banner">
			<div class="title">
				My Notes
			</div>
			<div class="wallet-address">
				{{ walletAddress }}
			</div>
		</div>
		<div class="notes-container">
			<div v-for="(note, index) in testNotesArray" :key="index" class="note-iframe-container">
				<NoteItem />
			</div>
		</div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';
import { formatNumber } from '@/utility';

// Icon imports.

// Component imports.
import Ethereum from '@/components/common/Ethereum.vue';
import NoteItem from './components/NoteItem.vue';

// Export the default component.
export default {
  components: {
		Ethereum,
		NoteItem
  },

	setup (props, context) {
    const store = useStore();
		const walletAddress = ref('');
		let testNotesArray = [

		]

		// Mount the component.
		onMounted(() => {

		});

		const initialize = function () {
			walletAddress.value = store.state.ethers.address;
			console.log(walletAddress.value)
		};

		return {
			initialize,
			walletAddress,
			testNotesArray
		}
	}
	};
</script>

<style scoped>
.profile-container {
	display: block;
	flex-direction: column;
  max-width: 1600px;
  color: white;
	margin: auto;
	min-height: 85vh;
	height: 85vh;
	margin-top: 10vh;
	margin-bottom: 5vh;
	user-select: text;
	max-width: 1200px;
}

	/* Banner Styling */
	.banner {
		 display: flex;
		 flex-direction: column;
		 height: 125px;
	 }

		.title {
			font-size: 60px;
			text-align: center;
		}

		.wallet-address {
			font-family: 'Lato', sans-serif;
			font-size: 20px;
			text-align: center;
			padding-top: 5px;
		}

	.notes-container {
		display: grid;
		overflow-y: auto;
		justify-content: center;
		grid-template-columns: repeat(auto-fill, 512px);;
		grid-template-rows: repeat(auto-fill, 512px);
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		border-top: solid 1px rgba(255, 255, 255, 0.1);
		box-sizing: border-box;
		padding: 40px;
		width: 100%;
		height: calc( 100% - 125px );
	}

	.note-iframe-container {
		width: 100%;
		height: 100%;
		min-height: 512px;
	}
</style>
