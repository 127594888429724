<template>
  <Ethereum :callback="() => {}" />

  <!-- Provide a modal with wallet connection options. -->
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="alert.showWalletModal">
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <!-- Provide a navigation bar in the header. -->
  <div class="navBar">
    <div class="container">

      <!-- Display the logo and project name. -->
      <div class="logo" @click.stop="routeTo('/')">
        <p class="link" id="logo-link">
          <DieIcon :size="64" />
					<span class="logo-text">
						<svg height="64px" width="130px">
							 <text x="0%" y="75%">
								 Die (Loot)
							 </text>
						 </svg>
					</span>
        </p>
      </div>

      <!-- Display core site options. -->
			<a class="link" @click.stop="routeTo('/mint')">
        Mint
      </a>
      <a class="link" @click.stop="routeTo('/about')">
        About
      </a>
      <a class="link" @click.stop="routeTo('/faq')">
        FAQ
      </a>

      <!-- Link to the project Discord. -->
      <div class="link" @click="openUrl('TODO')">
        <DiscordIcon class="discord-icon"/>
      </div>

      <!-- Link to the project Twitter. -->
      <div class="link" @click="openUrl('TODO')">
        <TwitterIcon class="twitter-icon"/>
      </div>

      <div class="menuIcon" @click.stop="showMobileMenu">
        <DieIcon :size="32" />
      </div>

      <!-- Prepare and display a menu compatible with mobile browsers. -->
      <MobileMenu
        :isVisible="mobileMenuIsVisible"
        :isHome="isHome"
        @toggleVisibility="mobileMenuIsVisible = false"
        @showWalletModal="showWalletModalDisplay"
      />

      <!-- Turn the user's address into a button. -->
      <button
        v-if="ethereum.address"
        class="address"
        @click.stop="toggleDropdown"
      >
        <div class="address-text">{{ profileAddressLabel }}</div>

        <!-- Display a down-arrow. -->
        <div class="svg-icon">
          <svg
            width="10"
            height="5"
            viewbox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0L5 5L10 0H0Z" fill="currentColor"></path>
          </svg>
        </div>

        <!-- Populate the dropdown options once a wallet is connected. -->
        <div class="profile-dropdown" v-if="showDropdown">
          <div class="dropdown-item" @click.stop="routeTo(`/profile/${ethereum.address}`)">
            <DieIcon :size="18"/>
            <div class="item-text">Profile</div>
          </div>
        </div>
      </button>

      <!-- The user is not connected to a signing provider. -->
      <button
        class="address"
        v-if="!ethereum.address && !isMobile"
        @click="showWalletModalDisplay"
      >
        Connect Wallet
      </button>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
import { useTask } from 'vue-concurrency';
import { ethers } from 'ethers';
import initializeConfig from '@/initialize-config';
import store from '@/store';
import router from '@/router';

// Component imports.
import Ethereum from '@/components/common/Ethereum.vue';
import WalletModal from '../layout/WalletModal.vue';
import MobileMenu from './MobileMenu.vue';

// Icon imports.
import DieIcon from '@/components/icons/DieIcon.vue';
import DiscordIcon from '@/components/icons/DiscordIcon.vue';
import TwitterIcon from '@/components/icons/TwitterIcon.vue';

// Set up the default header component.
export default {
  name: 'Header',

  components: {
    DieIcon,
    DiscordIcon,
    TwitterIcon,
    WalletModal,
    MobileMenu,
    Ethereum
  },

  data() {
    return {
      showDropdown: false,
      mobileMenuIsVisible: false
    };
  },

  computed: {
    ...mapState({
      alert: state => state.alert,
      ethereum: state => state.ethers
    }),

    isHome () {
      return router.currentRoute.value.name === 'Home';
    },

    profileAddressLabel () {
      let connectedAddress = this.ethereum.address;
      if (connectedAddress) {
        const shortenedAddress =
          connectedAddress.substring(0, 6) +
          '...' +
          connectedAddress.substring(connectedAddress.length - 4);
        return shortenedAddress;

        // If the user has not connected to a signing address, return no label.
      } else {
        return '';
      }
    },

    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
  },

  // When the component is mounted, add a listener to close the wallet connect modal on click.
  mounted () {
    document.addEventListener('click', this.close);
  },

  // When the component is unmounted, clear the wallet connect modal listener.
  onBeforeUnmount () {
    document.removeEventListener('click', this.close);
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      showWalletModal: 'alert/showWalletModal',
      hideWalletModal: 'alert/hideWalletModal'
    }),
    ...mapActions('ethers', ['connectWallet', 'disconnectWallet']),

    // TODO
    showWalletModalDisplay() {
      this.showWalletModal();
    },

    hideWalletModalDisplay() {
      this.hideWalletModal();
    },

    // Connect to an Ethereum wallet.
    connect() {
      this.connectWallet();
    },

    // Disconnect from an Ethereum wallet.
    disconnect() {
      this.disconnectWallet();
    },

    // Toggle the dropdown menu display.
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // Close the dropdown menu.
    close (event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    // Route to a different path.
    routeTo (path) {
      this.$router.push(path);
    },

    openUrl (url) {
      window.open(url, '_blank');
    },

    // Disable scrolling on window and show a mobile menu.
    showMobileMenu () {
      window.onscroll = function() {
        window.scrollTo(0, 0);
      };
      this.mobileMenuIsVisible = true;
    }
  },

  // Clear the application alert when routes change.
  watch: {
    $route(to, from) {
      this.clearAlert();
    }
  }
};
</script>

<style scoped lang="scss">
.discord-icon {
	transform: scale(1.3);
}

.twitter-icon {
	transform: scale(1.3);
}

svg text {
	stroke: white;
	font-size: 50px;
	font-weight: 100;
	stroke-width: 2;
	animation: textAnimate 3s;
	animation-fill-mode: forwards;
}

@keyframes textAnimate {
	0% {
		stroke-dasharray: 0 50%;
		stroke-dashoffset:  20%;
		fill:hsl(0, 68%, 0%)
	}

	100% {
		stroke-dasharray: 50% 0;
		stroke-dashoffstet: -20%;
		fill:hsl(0, 68%, 100%)
	}
}


.navBar {
  padding: 20px;

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logo {
      margin-right: auto;
			height: 60px;
    }

		#logo-link {
			padding: 10px 20px;
			max-height: 64px;
		}

    .link {
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 40px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 25px;
      border: none;
      height: fit-content;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      .logo-text {
        padding-left: 10px;
      }
    }

    .line {
      border-bottom: 2px solid white;
      width: 100px;
    }

    .menuIcon {
      width: 30px;
      height: 30px;
      grid-template-columns: auto;
      grid-row-gap: 5px;
      color: white;
      padding: 0px;
      border-radius: 50%;
      display: none;

      .line {
        border-bottom: 2px solid white;
        width: 30px;
        height: 10px;
        box-sizing: border-box;
        &:last-child {
          margin-bottom: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .connect {
      box-shadow: inset 0 0 0 1px rgb(128, 128, 128);
    }

    .address {
			font-family: 'Lato', sans-serif;
      right: 20px;
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 40px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 25px;
      background-color: transparent;
      border: none;
      width: fit-content;
      position: relative;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      .svg-icon {
        margin-left: 10px;
      }

      .profile-dropdown {
        position: absolute;
        top: 50px;
        right: 0px;
        width: 140px;
        padding: 10px;
        font-size: 14px;
        z-index: 10;

        .dropdown-item {
          padding: 10px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          transition: background-color 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
          }

          .item-text {
            font-size: 16px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.address-text {
	font-family: 'Lato', sans-serif;
	font-size: 20px;
}

.wallet-modal-container {
  position: fixed;
	font-family: 'Lato', sans-serif;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.profile-dropdown {
  position: absolute;
  top: 70px;
  right: 10px;
  width: 160px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.2);

  background-color: black;
  font-size: 14px;
  z-index: 10;
}

.dropdown-item {
  color: var(--text-color);
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.dropdown-item > p {
  margin-left: 10px;
  margin-bottom: 3px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
 {
  transform: translateY(-100px);
}

@media (max-width: 1050px) {
  .navBar .container {
  }
  .navBar .container .link {
    display: none;
  }

  .navBar .container .address {
    display: none;
  }

  .navBar .container .menuIcon {
    display: grid;
  }

  .navBar .container .logo {
    display: initial;
    width: fit-content;
    img {
      max-height: 49px;
    }
  }
}
</style>
