<template>
  <div class="terms">
    <h1>TERMS & CONDITIONS</h1>
    <div class="section">
      Choir is a collection of digital artworks (NFTs) running on the
      Ethereum network. This website is only an interface allowing participants
      to interact with Choir NFTs. Users are entirely responsible for the
      safety and management of their own private Ethereum wallets and validating
      all transactions and contracts generated by this website before approval.
      Furthermore, as the Choir smart contracts run on the Ethereum
      network, there is no ability to undo, reverse, or restore any
      transactions.
    </div>

    <div class="section">
      This website and its connected services are provided "as is" and "as
      available" without warranty of any kind. By using this website you are
      accepting sole responsibility for any and all transactions involving Choir NFTs.
    </div>

    <h1>OWNERSHIP</h1>
    <div class="section">
      1. You own the NFT. Each Choir NFT is an NFT on the Ethereum blockchain.
      Ownership of the NFT is mediated entirely by the smart contract and the
      Ethereum Network. The art contained within your NFT may change at any time
      as visual updates are applied to the underlying simulation.
    </div>
    <div class="section">
      2. Personal Use. Subject to your continued compliance with these Terms,
      Choir grants you a worldwide, royalty-free license to use, copy, and
      display the purchased art, along with any extensions that you choose to
      create or use, solely for the following purposes: (i) for your own
      personal, non-commercial use; (ii) as part of a marketplace that permits
      the purchase and sale of your Choir NFT, provided that the
      marketplace cryptographically verifies each Choir owner’s rights to
      display the art for their Choir NFT to ensure that only the actual
      owner can display the art; or (iii) as part of a third party website or
      application that permits the inclusion, involvement, or participation of
      your Choir NFT, provided that the website/application cryptographically
      verifies each Choir owner’s rights to display the art for their
      Choir NFT to ensure that only the actual owner can display the art,
      and provided that the art is no longer visible once the owner of the Choir
      NFT leaves the website/application.
    </div>

    <div class="section">
      3. Commercial Use. Subject to your continued compliance with these
      Terms, Choir grants you an unlimited, worldwide license to use,
      copy, and display the purchased art for the purpose of creating derivative
      works based upon the art ("Commercial Use"). Examples of such Commercial
      Use would e.g. be the use of the art to produce and sell merchandise
      products (T-Shirts etc.) displaying copies of the art. For the sake of
      clarity, nothing in this Section will be deemed to restrict you from (i)
      owning or operating a marketplace that permits the use and sale of Choir
      NFTs generally, provided that the marketplace cryptographically
      verifies each Choir NFT owner's rights to display the art for their
      Choir NFT to ensure that only the actual owner can display the art;
      (ii) owning or operating a third party website or application that permits
      the inclusion, involvement, or participation of Choir generally,
      provided that the third party website or application cryptographically
      verifies each Choir NFT owner’s rights to display the art for their
      Choir NFT to ensure that only the actual owner can display the art,
      and provided that the art is no longer visible once the owner of the
      Purchased Choir NFT leaves the website/application; or (iii) earning
      revenue from any of the foregoing. You alone are responsible for any other
      obligations that may arise from generative derivatives of another owner's
      intellectual property using your Choir NFT.
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>
<style scoped lang="scss">
.terms {
  padding: 60px;
  max-width: 1600px;
  color: white;
	margin: auto;
	height: 90vh;
	margin-top: 10vh;
	user-select: text;

  h1 {
    font-size: 30px;
    margin-bottom: 30px;
		font-family: 'Lato', sans-serif;
		text-align: center;
  }

  .section {
		font-family: 'Lato', sans-serif;
    margin-bottom: 20px;
    font-size: 20px;
		text-indent: 50px;
  }
}
</style>
