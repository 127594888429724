<template>
  <div class="attributions">
    <h1>Attributions</h1>
    <li>
      The music sheet image in the Mint page is from <a href="https://stockarch.com/images/recreation-and-sport/music/sheet-music-2999">Stockarch</a>.
    </li>
		<li>Instrument icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
  </div>
</template>

<style scoped>
.attributions {
	color: white;
	width: 100%;
	height: 90vh;
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	margin: auto;
	margin-top: 10vh;
	font-size: 20px;
	user-select: text;
	max-width: 1000px;
}

.attributions li {
	font-family: 'Lato', sans-serif;
	color: white;
	padding-top: 5px;
}

.attributions a {
	font-family: 'Lato', sans-serif;
	color: white;
}
</style>
