'use strict';

// Format a number with place delimiters.
export const formatNumber = function(value, decimals = 6) {
  value = value.toString().split('.');
  value[0] = value[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');
  if (value[1]) {
    value[1] = value[1].substring(0, decimals);
    value[1] = value[1].padEnd(decimals, '0');
  } else {
    value[1] = ''.padEnd(decimals, '0');
  }
  value = value.join('.');
  if (decimals === 0) {
    value = value.substring(0, value.length - 1);
  }
  return value;
};

// Format an address to have an ellipsis in the middle
export const formatAddress = function(address) {
  const length = address.length;
  if (length > 0) {
    const shortenedAddress = address.substring(0, 6) + '...' + address.substring(length - 4);
    return shortenedAddress;
  }
  return '';
};
