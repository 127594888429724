<template>
	<div class="router-view-container">
	  <router-view />
	</div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';

// Import components.

// Set up the default application component.
export default {
  name: 'Choir'
};
</script>

<style scoped>
.layout-manager {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
	overflow-y: auto;
	position: relative;
	width: 100%;
	user-select: none;
}

	.header-container {
		position: absolute;
		width: 100%;
		z-index: 10;
		max-height: 10vh;
	}

	.router-view-container {
		flex: 1;
		height: 100%;
	}

	.footer-container {
		position: absolute;
		bottom: 0%;
		text-shadow: 0 0 5px #ffffff;
		height: 100px;
		max-height: 5vh;
		width: 100%;
		z-index: 10;
	}
	</style>
