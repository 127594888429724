<template>
  <div class="stage">

    <!-- Render an overlay on the scene. -->
    <transition name="fade" mode="out-in">
      <div key="1" class="scene-controls">
        <div class="control-background">
        </div>
        <DieIcon key="1" class="control-button" :size="32" />
      </div>
    </transition>

    <!-- Render a container for ThreeJS. -->
    <div ref="container" class="container">
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ref, onMounted, watch, onUnmounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { loadScript } from 'vue-plugin-load-script';
import * as THREE from 'three';
import Stats from 'stats.js';
import { sleep } from '@/utility';
import * as CANNON from 'cannon-es';
import cannonDebugger from 'cannon-es-debugger';
import { DiceManager, DiceD6 } from './Dice.js';

// Icon imports.
import DieIcon from '@/components/icons/DieIcon.vue';

// Set up the default component.
export default {
	name: 'Table',

  // Register components.
  components: {
    DieIcon
  },

	// Set up the default component with its reactive data fields.
	setup (props, context) {
		const store = useStore();
    const route = useRoute();
    const container = ref(null);

    // Patch the ThreeJS prototype to fix an error thrown on some meshes.
    THREE.BufferAttribute.prototype.getX = function (index) { return this.array[index * this.itemSize] || 0; };
    THREE.BufferAttribute.prototype.getY = function (index) { return this.array[index * this.itemSize + 1] || 0; };
    THREE.BufferAttribute.prototype.getZ = function (index) { return this.array[index * this.itemSize + 2] || 0; };
    THREE.BufferAttribute.prototype.getW = function (index) { return this.array[index * this.itemSize + 3] || 0; };
    THREE.InterleavedBufferAttribute.prototype.getX = function (index) { return this.data.array[index * this.data.stride + this.offset] || 0; };
    THREE.InterleavedBufferAttribute.prototype.getY = function (index) { return this.data.array[index * this.data.stride + this.offset + 1] || 0; };
    THREE.InterleavedBufferAttribute.prototype.getZ = function (index) { return this.data.array[index * this.data.stride + this.offset + 2] || 0; };
    THREE.InterleavedBufferAttribute.prototype.getW = function (index) { return this.data.array[index * this.data.stride + this.offset + 3] || 0; };

    // Create the FPS display.
    const stats = new Stats();
    stats.showPanel(0);
    if (store.state.table.debug) {
      document.body.appendChild(stats.dom);
    }

    // Create the scene and renderer.
    let scene = new THREE.Scene();
    let renderer = new THREE.WebGLRenderer({ alpha: true });

    // Add a light to the scene.
		const light = new THREE.AmbientLight(0x404040);
		scene.add(light);

		// Prepare all rendering when the component is mounted.
    let camera;
		onMounted(async () => {
			console.log('Preparing table.');

      // Attach AmmoJS whenever this component is mounted.
      let ammoScript = await loadScript('Ammo.js');

      // Determine the size of the parent container.
      const containerWidth = container.value.offsetWidth;
      const containerHeight = container.value.offsetHeight

      // Initialize the renderer.
      renderer.setSize(containerWidth, containerHeight);

	    // Add the renderer to its parent container.
	    container.value.appendChild(renderer.domElement);

      // Create the camera.
      const cameraPosition = new THREE.Vector3(0, 30, 0);
      camera = new THREE.PerspectiveCamera(45, containerWidth / containerHeight, 0.01, 10000);
      camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
      camera.lookAt(0, 0, 0);

      // Prepare physics simulation.
      let world = new CANNON.World({
        gravity: new CANNON.Vec3(0, -9.82, 0)
      });

      // Add testing ground.
      const groundBody = new CANNON.Body({
        type: CANNON.Body.STATIC,
        shape: new CANNON.Plane()
      });
      groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
      world.addBody(groundBody);

      // Create the dice.
      DiceManager.setWorld(world);
      var dice = new DiceD6({
        size: 3,
        backColor: '#ff0000'
      });
      scene.add(dice.getObject());

      // Position the dice.
      dice.resetBody();
      dice.getObject().position.x = 0;
      dice.getObject().position.y = 10;
      dice.getObject().rotation.x = 0 * Math.PI / 180;
      dice.updateBodyFromMesh();

      // Set the result and physics of the dice.
      let yRand = Math.random() * 20;
      dice.getObject().position.x = 0;
      dice.getObject().position.y = 5 + yRand;
      dice.getObject().position.z = 0;
      dice.getObject().quaternion.x = (Math.random() * 90 - 45) * Math.PI / 180;
      dice.getObject().quaternion.z = (Math.random() * 90 - 45) * Math.PI / 180;
      dice.updateBodyFromMesh();
      dice.getObject().body.velocity.set(
        Math.random() * 10 - 5,
        Math.random() * 10 - 5,
        Math.random() * 10 - 5
      );
      dice.getObject().body.angularVelocity.set(
        20 * Math.random() - 10,
        20 * Math.random() - 10,
        20 * Math.random() - 10
      );
      DiceManager.prepareValues([ { dice: dice, value: 1 } ]);

      // Add scene debugging.
      // cannonDebugger(scene, world.bodies, { });

      // Track variables required to maintain a fixed framerate.
      const FRAMES_PER_SECOND = 60.0;
      const SKIP_TICKS = 1000.0 / FRAMES_PER_SECOND;
      let nextGameTick;
      let sleepTime = 0;

      // Animate a single frame of the simulation given the current time delta.
      const animate = async function (delta) {
        stats.begin();

        // Render the scene if the renderer exists. Swallow any rendering errors.
        if (renderer) {
          try {
            renderer.render(scene, camera);

            // Tick physics.
            world.step(1.0 / FRAMES_PER_SECOND);

            // Process physics objects.
            dice.updateMeshFromBody();

            // Log frame statistics.
            stats.end();

            // Initialize the next game tick to the current elapsed time.
            if (!nextGameTick) {
              nextGameTick = delta;
            }
            nextGameTick += SKIP_TICKS;
            sleepTime = nextGameTick - delta;
            if (sleepTime >= 0) {
              await sleep(sleepTime);
            } else {
            }

            // Request the window to immediately animate the next frame.
            window.requestAnimationFrame(animate);
          } catch (error) {
          }
        }
      };
      window.requestAnimationFrame(animate);
		});

    // Watch for a change to the store's debug flag and configure FPS display.
    watch (() => store.state.table.debug, async (newDebug) => {
      if (newDebug) {
        document.body.appendChild(stats.dom);
      } else {
        document.body.removeChild(stats.dom);
      }
    }, { deep: true });

    // Clean up all memory used by the scene by disposing of elements.
    const cleanup = function () {
      if (renderer) {
        renderer.dispose();
      }

      // A helper function to dispose of a material's textures.
      const cleanMaterial = (material) => {
        material.dispose();
        for (const key of Object.keys(material)) {
          const value = material[key];
          if (value && typeof value === 'object' && 'minFilter' in value) {
            value.dispose();
            value = null;
          }
        }
        material = null;
      };

      // Traverse all objects in the scene and dispose them.
      if (scene) {
        scene.traverse(object => {
          if (object.geometry) {
            object.geometry.dispose();
            object.geometry = null;
          }

          // If the object has material, also dispose that.
          if (object.material) {
            if (object.material.isMaterial) {
              cleanMaterial(object.material);

            // If the object has an array of materials, dispose them all.
            } else {
              for (const material of object.material) {
                cleanMaterial(material);
              }
            }
            object.material = null;
          }
          object = null;
        });
      }
      scene = null;
      renderer = null;
      if (container.value) {
        container.value.innerHtml = '';
      }

      // Remove the camera.
      camera = null;
    };

    // Watch for a route out of this component and perform necessary cleanup.
    watch (() => route.path, async newPath => {
      cleanup();
    });

    // Clean up the rendering data when the component is destroyed.
    onUnmounted(() => {
      cleanup();
    });

    // Return data required by the template.
		return {
	    container
		};
	}
};
</script>

<style scoped lang="scss">
.stage {
  position: relative;
  height: 100%;
  width: auto;
	cursor: grab;
}

.stage:active {
	cursor: grabbing;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #eee;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay:hover {
  color: #ccc;
}

.container {
  height: 100%;
  width: auto;
}

.logo-container {
  display: flex;
}

svg text {
	stroke: white;
	font-size: 50px;
	font-weight: 100;
	stroke-width: 2;
	animation: textAnimate 2s;
	animation-fill-mode: forwards;
}

@keyframes textAnimate {
	0% {
		stroke-dasharray: 0 50%;
		stroke-dashoffset: 20%;
		fill:hsl(0, 68%, 0%)
	}

	100% {
		stroke-dasharray: 50% 0;
		stroke-dashoffstet: -20%;
		fill:hsl(0, 68%, 100%)
	}
}

.logo-text {
  padding-left: 10px;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity 0.1s ease;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.scene-controls {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #eee;
  margin-bottom: 10px;
}

.control-button {
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-left: 10px
}

.control-button:hover {
  color: #ccc;
}

.control-background {
  position: absolute;
  margin-left: 8px;
  bottom: 2.5px;
  left: 1px;
  width: 76px;
  height: 34px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
}

.progress-bar-container {
  position: absolute;
  width:35%;
  margin-bottom: 10px;
  bottom: 8px;
  left: 100px;
}
</style>
