'use strict';

// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '@/pages/home/Home.vue';
import About from '@/pages/about/About.vue';
import Attributions from '@/pages/attributions/Attributions.vue';
import FAQ from '@/pages/faq/FAQ.vue';
import Mint from '@/pages/mint/Mint.vue';
import Terms from '@/pages/terms/Terms.vue';
import Profile from '@/pages/profile/Profile.vue';
import NotFound from '@/pages/not-found/NotFound.vue';

// Layout imports.
import MainLayout from './layouts/MainLayout.vue';

// Create routes.
const routes = [
  {
		path: '/',
    name: 'MainLayout',
    component: MainLayout,
		children: [
			{ path: '/', name: 'Home', component: Home },
			{
		    path: '/about',
		    name: 'About',
		    component: About
		  },
			{
		    path: '/attributions',
		    name: 'Attributions',
		    component: Attributions
		  },
		  {
		    path: '/faq',
		    name: 'FAQ',
		    component: FAQ
		  },
		  {
		    path: '/mint',
		    name: 'Mint',
		    component: Mint
		  },
		  {
		    path: '/terms',
		    name: 'Terms',
		    component: Terms
		  },
			{
		    path: '/profile/:address',
		    name: 'Profile',
		    component: Profile
		  },
			{ path: '/:pathMatch(.*)*', component: NotFound }
		]
  }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
