'use strict';

// Component imports.
import { createApp } from 'vue';
import VueFinalModal from 'vue-final-modal';
import Toaster from '@meforma/vue-toaster';
import LoadScript from 'vue-plugin-load-script';
import KProgress from 'k-progress-v3';

// Style imports.
import './assets/style/main.scss';

// Application imports.
import App from './App.vue';
import router from './router';
import store from './store';

// Create our application with required dependencies.
const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueFinalModal())
  .use(Toaster)
  .use(LoadScript)
  .component('k-progress', KProgress)
  .mount('#app');
