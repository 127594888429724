<template>
  <div class="main">
		<div class="landing-page">
			<div class="left-sidebar">
			</div>

	    <!-- Display a table for rolling dice. -->
			<div class="stage-container">
	    	<Table />
			</div>
			<div class="right-sidebar">
			</div>
  	</div>
		<div class="down-arrow-container">
			<DownCaretIcon class="down-arrow" />
		</div>
		<div class="info-page">
			<div class="info-title">
				Die (Loot)
			</div>
			<div class="info-text">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut nisl in neque fringilla dignissim. Sed vel orci arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed at diam urna. Phasellus eget imperdiet est. Vivamus eleifend vestibulum lacus, nec dignissim ligula eleifend mattis. Donec egestas auctor felis, non cursus risus interdum vel. Donec viverra, ligula eget sagittis fringilla, dolor quam maximus nisl, suscipit ultrices nisi mi non nunc.

				Morbi non ex vel ipsum tempor finibus. Sed maximus, felis dignissim ultrices aliquam, sem ipsum fringilla turpis, ac tempor augue sapien quis tellus. Maecenas a massa felis. Fusce euismod id elit ac molestie. Mauris id sem quam. Mauris nec mauris feugiat, mattis dolor convallis, facilisis dolor. Pellentesque aliquet maximus neque, at congue arcu lacinia eget. Quisque et ligula mollis enim accumsan auctor id a sapien. Donec bibendum, nulla sed eleifend cursus, diam nisi ultrices augue, id convallis diam ante sit amet nulla. Quisque libero est, luctus non nisl a, elementum interdum leo. Vestibulum posuere molestie dolor at convallis. Sed nec varius tortor.
			</div>
  	</div>
	</div>
</template>

<script>
'use strict';

// Imports.
import { computed } from 'vue';

// Component Imports.
import Table from '@/components/common/Table.vue';

// Icon imports.
import DownCaretIcon from '@/components/icons/DownCaretIcon.vue';

// Set up the default component.
export default {
	components: {
    Table,
		DownCaretIcon
  },

  // Set up the default component.
  setup (props) {
  }
};
</script>

<style scoped>
	.main {
		display: flex;
		flex-direction: column;
		height: 100%;

	}

/* Stage Content */
	.landing-page {
		display: flex;
		flex-direction: row;
		height: 97vh;
		justify-content: center;
		align-items: center;
	}

	/* Stage */
  .stage-container {
    flex: 10;
		max-width: 1000px;
		height: 80%;
		border: solid 1px rgba(255, 255, 255, 0.1);
		border-radius: 20px;
  }

	/* Left Sidebar */
	.left-sidebar {
		flex: 1;
		flex-grow: 1;
	}

	.left-sidebar-text-container {

	}

	.left-sidebar-text {
		display: inline-block;
	  font-family: Arial, Helvetica, sans-serif;
	  font-size: 60px;
	  color: white;
	}

/* Right sidebar */
	.right-sidebar {
		flex: 1;
		flex-grow: 1;
		color: white;
	}

/* Down arrow */
.down-arrow-container {
	color: white;
	display: flex;
	justify-content: center;
	height: 3vh;
}

.down-arrow {
	will-change: transform;
	animation: bounce linear 1s infinite alternate;
}

@keyframes bounce {
	0% {
		transform: translateY(0px) scale(3);
	}

	100% {
		transform: translateY(-10px) scale(3);
	}
}

/* Info Page */
.info-page {
	height: 50vh;
	color: white;
	display: flex;
	flex-direction: column;
	max-width: 1000px;
	width: 100%;
	margin: auto;
	font-family: 'Lato', sans-serif;
	user-select: text;
}

.info-title {
	font-size: 70px;
	padding-bottom: 20px;
}

.info-text {
	font-size: 20px;
	font-family: inherit;
	text-indent: 50px;
	line-height: 40px;
}

</style>
